<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Account Statement</h4>
      <form @submit.prevent="fetch">
        <div class="form-row mb-5">
          <div class="col-md-5">
            <div class="form-group">
              <label>Date From</label>
              <date-picker
                input-class="form-control"
                placeholder="Date From"
                v-model="filter.date_from"
                format="dd-MM-yyyy"
              />
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label>Date From</label>
              <date-picker
                input-class="form-control"
                placeholder="Date From"
                v-model="filter.date_to"
                format="dd-MM-yyyy"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>.</label>
              <button class="btn btn-primary btn-xs d-block">
                <i class="lni-search mr-2"></i>Apply
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="summary">
        <h5 class="text-center text-primary">Summary</h5>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Transaction Type</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Account Topup</td>
              <td>{{ $number.format(summary.topup) }}</td>
            </tr>
            <tr>
              <td>Bulk SMS Sent</td>
              <td>{{ $number.format(summary.bulk_sms) }}</td>
            </tr>
            <tr>
              <td>Balance</td>
              <td>
                <strong>{{ $number.format(summary.balance) }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="detailed mt-5">
        <h5 class="text-center text-primary">Detailed</h5>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Date</th>
              <th>Credit</th>
              <th>Debit</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, i) in statement" :key="`line-${i}`">
              <td>{{ line.date }}</td>
              <td>{{ $number.format(line.credit) }}</td>
              <td>{{ $number.format(line.debit) }}</td>
              <td>{{ $number.format(line.balance) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      statement: [],
      summary: {},
      filter: {
        date_from: "",
        date_to: "",
      },
    };
  },

  methods: {
    fetch() {
      this.$loader.start("main-content");
      this.$axios
        .get(`/api/v1/apps/${this.$route.params.id}/statement?date_from=${this.filter.date_from != '' ? moment(this.filter.date_from).format('YYYY-MM-DD') : ''}&date_to=${this.filter.date_to != '' ? moment(this.filter.date_to).format('YYYY-MM-DD') : ''}`)
        .then((response) => {
          this.statement = response.data.statement;
          this.summary = response.data.summary;
          this.$loader.stop();
        });
    },
  },

  computed: {
    app() {
      return this.$store.getters.getApp;
    },
  },
};
</script>
